/* eslint-disable react/display-name */
import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Disqus } from 'gatsby-plugin-disqus';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import ContentfulImage from '../components/ContentfulImage';
import CardAuthor from '../components/CardAuthor';

const options = {
  renderMark: {
    [MARKS.CODE]: (text) => (
      <SyntaxHighlighter language='javascript' style={okaidia}>
        {text}
      </SyntaxHighlighter>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const imageId = node.data.target.sys.id;
      return <ContentfulImage key={imageId} id={imageId} />;
    },
    // [BLOCKS.EMBEDDED_ENTRY]: (node) => (
    //   <>
    //     <h3>Embedded Entry</h3>
    //     <pre>{JSON.stringify(node)}</pre>
    //   </>
    // )
  },
};

const Blog = ({ data, intl }) => {
  const { category, cover, createdAt, slug, title } = data.contentfulBlog;
  const { raw, references } = data.contentfulBlog.post;
  const siteMeta = data.site.siteMetadata;
  const bodyHtml = JSON.parse(raw);

  return (
    <Layout bodyClass='page-default-single'>
      <div className='container pb-6 pt-10 pt-md-10 pb-md-10'>
        <div className='row justify-content-center article-content'>
          <div className='col-12 col-md-10 col-lg-8'>
            <h1 className='title'>{title}</h1>
            <span>
              <img
                src={withPrefix('/images/avatar.png')}
                loading='lazy'
                width='32'
                alt='Avatar'
              />
              &nbsp;&nbsp;&nbsp;
              <strong>Yasin Junet</strong>
              &nbsp;&nbsp;&middot;&nbsp;&nbsp;
              {createdAt}
              &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            </span>
            <span className='category'>{category.name}</span>
            {cover && (
              <div className='mt-3 blog-image'>
                <GatsbyImage image={cover.gatsbyImageData} alt={cover.title} />
              </div>
            )}
            <div className='content'>
              {documentToReactComponents(bodyHtml, options)}
            </div>
            <CardAuthor />
            <Disqus
              config={{
                url: `${siteMeta.url}/blog/${slug}`,
                identifier: `/blog/${slug}`,
                title: title,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      id
      slug
      title
      excerpt
      category {
        name
      }
      createdAt(formatString: "MMMM DD, YYYY")
      cover {
        title
        gatsbyImageData
        file {
          url
        }
        id
      }
      post {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        description
        title
        url
        username
      }
    }
    allSocialJson {
      edges {
        node {
          name
          image
          link
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const { cover, slug, title, excerpt } = data.contentfulBlog;
  return (
    <>
      <SEO
        title={title}
        url={`/blog/${slug}`}
        desc={excerpt}
        image={`https:${cover?.file?.url}`}
      />
      <meta property='og:type' content='article' />
    </>
  );
};

export default Blog;
